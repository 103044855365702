import { useTwoFactorAuthenticationStore } from "@/store/TwoFactorAuthenticationStore";

export default function useTwoFactorAuthentication() {
  const twoFactorAuthenticationStore = useTwoFactorAuthenticationStore();

  function enableTwoFactorAuthentication() {
    twoFactorAuthenticationStore.enable();
  }

  function disableTwoFactorAuthentication() {
    twoFactorAuthenticationStore.disable();
  }

  function isTwoFactorAuthenticationEnabled() {
    return twoFactorAuthenticationStore.enabled;
  }

  return {
    enableTwoFactorAuthentication,
    disableTwoFactorAuthentication,
    isTwoFactorAuthenticationEnabled,
  };
}
