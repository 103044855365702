import { computed } from "vue";

import { useMarketplace } from "@/shared/composables/Marketplace";
import { MarketplaceCode } from "@/shared/enums/MarketplaceCodeEnum.ts";

export const useIntegrationSeller = () => {
  const { isMarketplace } = useMarketplace();

  const urlTikTokShop =
    "https://services.tiktokshops.us/open/authorize?service_id=7423912529452451626";

  const idTikTokShopModal = "tiktok-shop-modal";
  const idIntegrationSuccessModal = "integration-success-modal";

  const isEnabledIntegrations = computed((): boolean => {
    return isMarketplace(MarketplaceCode.Us);
  });

  return {
    idTikTokShopModal,
    idIntegrationSuccessModal,
    urlTikTokShop,
    isEnabledIntegrations,
  };
};
