import { defineStore } from "pinia";

export interface WebSocketAuthenticationState {
  isAuthenticated: boolean;
}

export const useWebSocketAuthenticationStore = defineStore(
  "web-socket-authentication",
  {
    state: (): WebSocketAuthenticationState => ({
      isAuthenticated: false,
    }),
    actions: {
      setAuthenticated(authenticated = false) {
        this.isAuthenticated = authenticated;
      },
    },
  }
);
