<template>
  <DsSidebar v-model="isShowing" width-css-class="mobile-menu-sidebar">
    <template #body>
      <div class="mobile-menu-sidebar-body">
        <div class="mobile-menu-sidebar-header">
          <div class="flex items-start justify-between">
            <DsAvatar :alt="fullName" size="md" />
            <div class="ml-3 flex h-7 items-center">
              <button
                class="cursor-pointer text-gray-900 h-8 w-8"
                @click="isShowing = !isShowing"
              >
                <DsCloseOutlineIcon />
              </button>
            </div>
          </div>
          <p class="text-lg font-medium text-gray-900 pt-2 truncate">
            {{ fullName }}
          </p>
          <p class="text-gray-500 text-sm font-normal">
            {{ email }}
          </p>
        </div>
        <div class="relative my-6 flex-1 overflow-y-auto">
          <ul>
            <li
              v-for="(item, key) in items"
              :key="key"
              class="space-y-2 flex flex-col px-4"
            >
              <DsLink
                :data-testid="item.dataTestid"
                :to="item.route"
                :class="[
                  isActive(item) ? 'link-offcanvas-active' : 'link-offcanvas',
                  item.cssClasses,
                ]"
                @click="onClose"
              >
                {{ item.label }}
                <DsComponentRender
                  v-if="item.suffix"
                  :component="item.suffix"
                />
              </DsLink>
            </li>
          </ul>
          <ul class="border-t border-gray-200 mt-4 pt-4">
            <li
              v-for="(item, key) in itemsAccount"
              :key="key"
              class="space-y-2 flex flex-col px-4"
            >
              <DsLink
                v-if="Object.keys(item.route).length > 0"
                :data-testid="item.dataTestid"
                :to="item.route"
                :class="[
                  isActive(item) ? 'link-offcanvas-active' : 'link-offcanvas',
                  item.cssClasses,
                ]"
                @click="onClose"
              >
                {{ item.label }}
              </DsLink>
              <DsButton
                v-else
                theme-display="link"
                :data-testid="item.dataTestid"
                class="link-offcanvas text-left"
                @click.prevent="callFunction(item)"
              >
                {{ item.label }}
              </DsButton>
            </li>
          </ul>
          <!-- /End replace -->
        </div>
        <div class="mobile-menu-sidebar-footer">
          <DsLink :to="{ name: 'Logout' }" class="block link-offcanvas">
            {{ t("common.route.logout.link") }}
          </DsLink>
        </div>
      </div>
    </template>
  </DsSidebar>
</template>

<script setup lang="ts">
import { computed, watch } from "vue";

import { DsComponentRender } from "@devsalsa/vue-core";
import {
  DsAvatar,
  DsButton,
  DsCloseOutlineIcon,
  DsLink,
  DsSidebar,
} from "@devsalsa/vue-core";

import type { NavigationItem } from "@/core/shared/components/Navigation/Navigation.types";
import AccountHandler from "@/core/shared/helpers/Account/AccountHandler";
import Grid from "@/core/shared/helpers/Grid";

import { useI18n } from "vue-i18n";
import { type LocationAsRelativeRaw, useRoute } from "vue-router";

defineOptions({
  name: "OffCanvas",
});

type OffCanvasProps = {
  isOpen: boolean;
  items: NavigationItem[];
  itemsAccount: NavigationItem[];
};

const { items, itemsAccount } = defineProps<OffCanvasProps>();

const isShowing = defineModel<boolean>("isOpen", { default: false });

const { t } = useI18n();
const route = useRoute();

const visibleGrid = computed((): boolean => {
  return Grid("xl:hidden");
});
const email = computed((): string => {
  return AccountHandler.accountInfo.value?.email ?? "";
});
const fullName = computed((): string => {
  return `${AccountHandler.accountInfo.value?.first_name ?? ""} ${AccountHandler.accountInfo.value?.last_name ?? ""}`;
});

const onClose = (): void => {
  isShowing.value = false;
};
const isActive = (item: NavigationItem): boolean => {
  return route.name === (item.route as LocationAsRelativeRaw).name;
};
const callFunction = (item: NavigationItem): void => {
  if (item.action && typeof item.action === "function") {
    onClose();
    item.action();
  }
};

watch(visibleGrid, (newValue) => {
  isShowing.value = isShowing.value && newValue;
});
</script>

<style lang="scss" scoped>
.link-offcanvas {
  @apply text-gray-500 text-sm font-medium p-2 no-underline hover:no-underline
  hover:text-gray-900 hover:bg-gray-100 hover:rounded-lg;
  &-active {
    @apply text-sm font-medium py-2 px-2 text-gray-900 bg-gray-100 rounded-lg no-underline hover:no-underline;
  }
}

.mobile-menu-sidebar {
  &-header {
    @apply px-6 border-b border-gray-200 pb-4 z-10 bg-white sticky top-0 pt-6;
  }

  &-body {
    @apply flex h-full flex-col relative;

    :deep(.ds-badge) {
      @apply ml-5 rounded-full;
    }
  }

  &-footer {
    @apply border-t border-gray-200 w-full bg-white bottom-0 p-4 sticky;
  }
}
</style>
