import { defineStore } from "pinia";

export interface TwoFactorAuthenticationState {
  enabled: boolean;
}

export const useTwoFactorAuthenticationStore = defineStore("api", {
  state: (): TwoFactorAuthenticationState => ({
    enabled: false,
  }),
  actions: {
    enable(): void {
      this.enabled = true;
    },
    disable(): void {
      this.enabled = false;
    },
  },
});
