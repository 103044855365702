import type {
  DsLiveNotification,
  DsLiveNotificationToast,
} from "@devsalsa/vue-core";

export default class NotificationRouterLink {
  static readonly JOB_CONTENT = "job_content";
  static readonly JOB_DELIVERY = "job_delivery";
  static readonly CAMPAIGN_CREATORS = "campaign_creators";
  static readonly WALLET = "wallet";
  static readonly PROMO_WALLET = "promo_wallet";
  static readonly JOB_CHAT = "job_chat";
  static readonly DASHBOARD = "dashboard";
  static readonly CAMPAIGN = "campaign";
  static readonly CERTIFICATION_REJECTED = "certification_rejected";
  static readonly INVOICES_BULK = "invoices_bulk";
  static readonly CREATOR_PAYOUT = "creator_payout";
  static readonly WIRE_TRANSFER_PROCESSED = "wire_transfer_processed";
  static readonly REFERRAL = "referral";
  static readonly PAYPAL = "paypal";
  static readonly TIKTOK_AD_CENTER = "tiktok_ad_center";
  static readonly WHITE_LABEL_HOST = "whitelabel_host";

  static get(notification: DsLiveNotification | DsLiveNotificationToast) {
    if (notification.action_type === NotificationRouterLink.TIKTOK_AD_CENTER) {
      return {
        name: "AdCenter",
      };
    }
    if (notification.action_type === NotificationRouterLink.PAYPAL) {
      return {
        name: "WithdrawOptions",
      };
    }
    if (notification.action_type === NotificationRouterLink.JOB_CONTENT) {
      return {
        name: "JobSummary",
        params: { jobId: notification.action_id },
        hash: "#job-content",
      };
    }
    if (notification.action_type === NotificationRouterLink.JOB_DELIVERY) {
      return {
        name: "JobSummary",
        params: { jobId: notification.action_id },
        hash: "#job-delivery",
      };
    }
    if (notification.action_type === NotificationRouterLink.JOB_CHAT) {
      return {
        name: "JobSummary",
        params: { jobId: notification.action_id },
        hash: "#job-chat",
      };
    }
    if (notification.action_type === NotificationRouterLink.CAMPAIGN_CREATORS) {
      return {
        name: "CampaignSummary",
        params: { campaignId: notification.action_id },
        hash: "#creators",
      };
    }
    if (notification.action_type === NotificationRouterLink.WALLET) {
      return {
        name: "Wallet",
      };
    }
    if (notification.action_type === NotificationRouterLink.PROMO_WALLET) {
      return {
        name: "Wallet",
        hash: "#promo",
      };
    }
    if (notification.action_type === NotificationRouterLink.REFERRAL) {
      return {
        name: "AffiliateProgram",
      };
    }

    if (notification.action_type === NotificationRouterLink.DASHBOARD) {
      return {
        name: "Dashboard",
      };
    }
    if (
      notification.action_type === NotificationRouterLink.CERTIFICATION_REJECTED
    ) {
      return notification.action_url;
    }
    if (notification.action_type === NotificationRouterLink.CAMPAIGN) {
      return {
        name: "CampaignSummary",
        params: { campaignId: notification.action_id },
      };
    }
    if (notification.action_type === NotificationRouterLink.WHITE_LABEL_HOST) {
      return {
        name: "WhiteLabeling",
      };
    }
    if (
      notification.action_type === NotificationRouterLink.CERTIFICATION_REJECTED
    ) {
      return notification.action_url;
    }
    if (notification.action_type === NotificationRouterLink.INVOICES_BULK) {
      const url = new URL(window.location.href);
      const startDate = url.searchParams.get("start_date");
      const endDate = url.searchParams.get("end_date");

      const link = document.createElement(`a`);
      link.target = "_blank";
      link.href = notification.action_url;
      link.click();

      return {
        name: "Billing",
        query: {
          start_date: startDate,
          end_date: endDate,
        },
      };
    }
    if (notification.action_type === NotificationRouterLink.CREATOR_PAYOUT) {
      return notification.action_url;
    }
    if (
      notification.action_type ===
      NotificationRouterLink.WIRE_TRANSFER_PROCESSED
    ) {
      return notification.action_url;
    }

    return { name: "" };
  }
}
