import type { RouteLocationNormalized } from "vue-router";
import type { NavigationGuardNext } from "vue-router";

import { useIntegrationSeller } from "@/modules/settings/composables/IntegrationSeller.ts";

export default class IntegrationsGuard {
  static async enabled(
    _: RouteLocationNormalized,
    __: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    const { isEnabledIntegrations } = useIntegrationSeller();

    if (!isEnabledIntegrations.value) {
      return next({
        name: "Dashboard",
      });
    }
    next();
  }
}
