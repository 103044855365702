import { ApiService } from "@/core/shared/services/ApiService";

import type { AxiosProgressEvent } from "axios";

import type {
  CostCalculation,
  CostCalculationData,
  MinCost,
  RecurringCampaign,
  RecurringCampaignFilters,
  RecurringCampaigns,
  RecurringCampaignsStats,
  RecurringCampaignStatusType,
  RecurringCampaignUpdated,
  RecurringFormData,
  UploadCoverResponse,
} from "@/modules/campaign/services/RecurringCampaignService.types";
import type { CreditCardUpdated } from "@/shared/services/CreditCardService.types";

import type { UgcTypeName } from "@/shared/enums/UgcTypeEnum";

export const RECURRING_CONTRACT_MAX_SALARY = 3000000;

export default class RecurringCampaignService {
  static assetTypes(): Promise<UgcTypeName[]> {
    return ApiService.get("/company/enums/recurring-campaigns/types");
  }

  static assetFrequency(): Promise<string[]> {
    return ApiService.get("/company/enums/recurring-campaigns/slot-frequency");
  }

  static assetCycleFrequency(): Promise<string[]> {
    return ApiService.get("/company/enums/recurring-campaigns/cycle-frequency");
  }

  static assetCampaignStatus(): Promise<RecurringCampaignStatusType[]> {
    return ApiService.get("/company/enums/recurring-campaigns/campaign-status");
  }

  static assetContractStatus(): Promise<string[]> {
    return ApiService.get("/company/enums/recurring-campaigns/contract-status");
  }

  static assetStatus(): Promise<string[]> {
    return ApiService.get("/company/enums/recurring-campaigns/slot-status");
  }

  static uploadCover(
    data: FormData,
    onUploadProgress?: (event: AxiosProgressEvent) => void
  ): Promise<UploadCoverResponse> {
    return ApiService.post(
      `/company/temporary-files/upload-image`,
      data,
      undefined,
      undefined,
      onUploadProgress
    );
  }

  /**
   * @endpoint /company/recurring-campaigns/campaign_id
   * @httpMethod GET
   * @param campaignId
   * @throws {ApiServiceError}
   * @returns Promise<RecurringCampaign>
   */
  static get(campaignId: number): Promise<RecurringCampaign> {
    return ApiService.get(`/company/recurring-campaigns/${campaignId}`);
  }

  static create(data: RecurringFormData): Promise<RecurringCampaign> {
    return ApiService.post("/company/recurring-campaigns", { ...data });
  }

  static getMinCosts(
    marketplace_id: number,
    numberVideos: number
  ): Promise<MinCost[]> {
    return ApiService.post(`/common/assets/recurring-type-min-cost`, {
      marketplace_id,
      video_count: numberVideos,
    });
  }

  static getCostCalculation(
    data: CostCalculationData
  ): Promise<CostCalculation> {
    return ApiService.post(
      `/company/recurring-campaigns/cost-calculation`,
      data
    );
  }

  static getCampaigns(
    data: RecurringCampaignFilters,
    page: number,
    rows = 100
  ): Promise<RecurringCampaigns> {
    return ApiService.post(
      `/company/search/recurring-campaigns/${page}/${rows}`,
      data
    );
  }

  static updatePaymentMethod(
    campaignId: number,
    creditCardId: number,
    useCompanyWallet: number
  ): Promise<CreditCardUpdated> {
    return ApiService.put(
      `/company/recurring-campaigns/${campaignId}/charge-settings`,
      {
        credit_card_id: creditCardId,
        use_company_wallet: useCompanyWallet,
      }
    );
  }

  static stats(): Promise<RecurringCampaignsStats> {
    return ApiService.get("/company/recurring-campaigns/stats");
  }

  static archive(campaignId: number): Promise<RecurringCampaignUpdated> {
    return ApiService.post(
      `/company/recurring-campaigns/${campaignId}/archive`
    );
  }
}
